<template>
	<div class="float-actions row" v-if="buttonsSecondary.length > 0 || selected.length > 0">
		<Action
			v-for="button in buttonsSecondary"
			:key="button.button_id"
			v-show="button.minimum_selected <= selected.length || button.minimum_selected === undefined"
			:name="button.name"
			:icon="button.icon"
			:className="button.className"
			:allow_access="button.allow_access"
			:dropdown_name="button.dropdown_name"
			:confirm_message="button.modal_options ? button.modal_options.text_message : ''"
			:confirm_title="button.modal_options ? button.modal_options.text_title : ''"
			:confirm_question="button.modal_options ? button.modal_options.text_question : ''"
			:confirm_button_ok="button.modal_options ? button.modal_options.text_button_ok : ''"
			:confirm_button_cancel="button.modal_options ? button.modal_options.text_button_cancel : ''"
			:action="button.action"
			:selected_fn="getSelected"
			:spin="button.spin"
			:button_action_event_only="buttonActionEventOnly"
			:uniqueEventId="uniqueEventId"
		/>
		<div v-for="(dropdown, key) in dropdowns" v-bind:key="key">
			<b-dropdown id="dropdown" dropup :text="getTrad('action.'+dropdown) + ' ▴'">
				<Action
					v-for="button in filtredDropdown(dropdown)"
					:key="button.button_id"
					v-show="button.minimum_selected <= selected.length || button.minimum_selected === undefined"
					:name="button.name"
					:icon="button.icon"
					:className="button.className"
					:allow_access="button.allow_access"
					:dropdown_name="button.dropdown_name"
					:confirm_message="button.modal_options ? button.modal_options.text_message : ''"
					:confirm_title="button.modal_options ? button.modal_options.text_title : ''"
					:confirm_question="button.modal_options ? button.modal_options.text_question : ''"
					:confirm_button_ok="button.modal_options ? button.modal_options.text_button_ok : ''"
					:confirm_button_cancel="button.modal_options ? button.modal_options.text_button_cancel : ''"
					:action="button.action"
					:selected_fn="getSelected"
					:spin="button.spin"
					:button_action_event_only="buttonActionEventOnly"
					:uniqueEventId="uniqueEventId"
				/>
			</b-dropdown>
		</div>
		<a @click="unselectAll" class="float-right btn btn-secondary"><font-awesome-icon :icon="['far', 'times-circle']" /> {{selected.length}} {{ $t('action.ligne') }}<template v-if="selected.length>1">s</template></a>
		<b-dropdown
			v-if="hasExportOption"
			lazy
			right
			:disabled="isExporting || selected.length === 0"
			v-b-tooltip.hover="selected.length === 0 ? $t('table.general.select_items_first'): ''"
			style="border-left: 1px solid rgba(255, 255, 255, 0.2);"
		>
			<template v-slot:button-content>
				<font-awesome-icon v-if="isExporting" :icon="['fas', 'spinner']" pulse />
				<font-awesome-icon v-else :icon="['fal', 'download']" />
				&nbsp;<span>{{ $t('action.export') }} ▴</span>
			</template>
			<b-dropdown-item @click="$emit('onExportCsvVirgule')">{{ $t('action.export_csv_virgule') }}</b-dropdown-item>
			<b-dropdown-item @click="$emit('onExportCsvPointVirgule')">{{ $t('action.export_csv_pt_virgule') }}</b-dropdown-item>
			<b-dropdown-item @click="$emit('onExportExcel')">{{ $t('action.export_excel') }}</b-dropdown-item>
		</b-dropdown>

	</div>
</template>

<script>
export default {
	name: 'TableActions',
	props: [
		'buttons',
		'selected',
		'getSelected',
		'buttonActionEventOnly',
		'uniqueEventId',
		'hasExportOption',
		'isExporting'
	],
	computed: {
		buttonsSecondary() {
			return this.buttons.filter(btn => !['btn-primary', 'd-none', 'btn-primary btn-lg'].includes(btn.className) && !btn.dropdown_name)
		},
		dropdowns() {
			let buttons_in_dropdowns = this.buttons.filter(btn => !['btn-primary', 'd-none'].includes(btn.className) && btn.dropdown_name)
			return [...new Set(buttons_in_dropdowns.map(item => item.dropdown_name))]
		},
	},
	components: {
        Action: () => import('GroomyRoot/components/Buttons/TableAction'),
	},
	methods: {
		unselectAll() {
			this.$emit('unselectAll')
        },
		filtredDropdown(name) {
			return this.buttons.filter(btn => !['btn-primary', 'd-none'].includes(btn.className) && btn.dropdown_name == name)
		},
		onExportCsvVirgule(){
			this.$emit('onExportCsvVirgule')
		},
		onExportCsvPointVirgule(){
			this.$emit('onExportCsvPointVirgule')
		},
		onExportExcel(){
			this.$emit('onExportExcel')
		}
	}
}
</script>
